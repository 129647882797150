<template>
    <router-link
        :is="selectState ? 'div' : 'router-link'"
        tag="li"
        class="inbox_item"
        tabindex="0"
        :class="{
            'inbox_item--unread': !read,
            'inbox_item--selected': selected,
        }"
        :to="{
            name: 'InboxDetail',
            params: { page, type, ID },
            query: { ...$route.query, chunkIndex: page },
        }"
    >
        <div
            class="inbox_item_container"
            @click="
                selectState && !areAllSelected
                    ? selectOrUnselectReview({ selection: !isSelected, id: ID })
                    : ''
            "
        >
            <SelectedCheckbox
                v-if="selectState"
                class="selected_checkbox"
                :id="ID"
                :value="isSelected"
                :disabled="areAllSelected"
            />
            <div style="width: 100%">
                <header class="inbox_item__header">
                    <span class="inbox_item__name" :title="consumerName">{{ consumerName }}</span>
                    <time>
                        <TimeAgo class="inbox_item__date" :date="date" />
                    </time>
                </header>
                <main class="inbox_item__main">
                    <strong
                        v-if="hasStrongTitleContent"
                        :class="{
                            inbox_item__title: true,
                            'inbox_item__title--unread': !read,
                            'inbox_item__title--selected': selected,
                        }"
                    >
                        <slot name="titleStrong"></slot>
                    </strong>
                    <span
                        :class="{
                            inbox_item__excerpt: true,
                            'inbox_item__excerpt--unread': !read,
                            'inbox_item__excerpt--selected': selected,
                        }"
                        v-line-clamp:2="2"
                    >
                        {{ excerpt }}
                    </span>
                </main>
                <footer class="inbox_item__footer">
                    <div class="inbox_item__icons">
                        <slot name="icons" />
                        <slot name="stars"></slot>
                    </div>
                </footer>
            </div>
        </div>
    </router-link>
</template>

<script>
import TimeAgo from "@/components/Global/Atoms/TimeAgo.vue";
import SelectedCheckbox from "@/components/Inbox/Atoms/SelectedCheckbox.vue";
import { mapState, mapMutations } from "vuex";

/* Inbox Item Base
 * This is the base item used for inbox items,
 * it only contains the shared properties between:
 * - Merchant Reviews
 * - Product Reviews
 * - Product Questions
 */

const baseProps = {
    type: {
        type: String,
        required: true,
    },
    primaryColour: {
        type: String,
        default: "blue",
    },
    consumerName: {
        type: String,
        default: "Anonymous",
    },
    date: {
        type: String,
        required: true,
    },
    excerpt: {
        type: String,
        required: true,
    },
    ID: {
        required: true,
    },
    read: {
        type: Boolean,
        required: true,
    },
    published: {
        type: Boolean,
        required: true,
    },
    replied: {
        type: Boolean,
        required: true,
    },
    revised: {
        type: Boolean,
        required: true,
    },
    answered: {
        type: Boolean,
        required: true,
    },
    page: {
        type: Number,
        default: 1,
    },
    hasUploadedImage: {
        type: Boolean,
        required: true,
    },
    isSelected: {
        type: Boolean,
    },
};

export { baseProps };

export default {
    name: "InboxItem",
    components: {
        TimeAgo,
        SelectedCheckbox,
    },
    props: baseProps,
    computed: {
        hasStrongTitleContent() {
            return !!this.$slots.titleStrong;
        },
        selected() {
            return (
                this.$route.params.ID &&
                this.$route.params.ID.toString() === this.ID.toString() &&
                this.$route.params.type === this.type
            );
        },
        ...mapState({
            selectState: (state) => state.InboxStore.selectState,
            areAllSelected: (state) => state.InboxStore.areAllSelected,
        }),
    },
    methods: {
        ...mapMutations({
            selectOrUnselectReview: "InboxStore/selectOrUnselectReview",
        }),
    },
    watch: {
        selected() {
            if (!this.selected) return;
            this.$el.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_item {
    position: relative;
    padding: 16px 24px;
    padding-left: 10px;
    background: #f6f6f8;
    border-bottom: 1px solid $grey_athens;
    outline: 0;

    &.inbox_item--unread {
        background: $white;
    }
    &:hover {
        box-shadow: 0 0 10px 0 rgba(30, 30, 30, 0.08);
        z-index: 2;
        cursor: pointer;
    }
    &.inbox_item--selected {
        box-shadow: 0 0 10px 0 rgba(30, 30, 30, 0.15);
        background: $white;
        z-index: 4;
        border-left: 4px solid $blue;
    }
}
.inbox_item_container {
    display: flex;
}
.inbox_item__header {
    @extend %body2_style;
    color: $grey_mid;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 4px;
}
.inbox_item__date {
    white-space: nowrap;
    font-size: 0.75rem;
}
.inbox_item__name {
    letter-spacing: 0.39px;
}
.inbox_item__title {
    display: inline-block;
    width: 100%;
    font-weight: 700;
    color: $grey-mid;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;

    &.inbox_item__title--unread {
        color: $black;
    }
    &.inbox_item__title--selected {
        color: $black;
    }
}
.inbox_item__main {
    @extend %body2_style;
    letter-spacing: 0;
}
.inbox_item__excerpt {
    margin-bottom: 12px;
    color: $grey-mid;

    &.inbox_item__excerpt--unread {
        color: $black;
    }
    &.inbox_item__excerpt--selected {
        color: $black;
    }
}
.inbox_item__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.inbox_item__icons {
    height: 16px;
    display: flex;
}
.selected_checkbox {
    margin-right: 10px;
    margin-top: 5px;
}
</style>
