import * as Sentry from "@sentry/vue";
import httpGet from "@/helpers/httpGet";

const NotificationStore = {
    namespaced: true,
    state: {
        notificationsLoading: true,
        merchantReviewUnseenCount: null,
        productReviewUnreadCount: null,
        productQuestionUnansweredCount: null,
        notificationTrayOpenPercentage: 0,
        newProductReviewsEnabled: null,
        productReviewsEnabled: null,
        productQuestionsEnabled: null,
    },
    mutations: {
        notificationTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined && this.state.trayDragAllowed) {
                state.notificationTrayOpenPercentage = openPercentage;
            }
        },
        setNotificationCount(state, { key, count }) {
            state[key] = count;
        },
        setLoading(state, loading) {
            state.notificationsLoading = loading;
        },
    },
    getters: {
        notificationCount(state) {
            return (
                state.merchantReviewUnseenCount +
                (state.productReviewUnreadCount || 0) +
                (state.productQuestionUnansweredCount || 0)
            );
        },
        allowedReviewTypes(state) {
            const productReviews =
                (state.newProductReviewsEnabled && state.productReviewsEnabled) ||
                state.productReviewsEnabled;
            const productQuestions =
                state.productQuestionsEnabled &&
                state.newProductReviewsEnabled &&
                state.productReviewsEnabled;
            const companyReviews = true;
            const types = { productReviews, productQuestions, companyReviews };
            return ["productReviews", "productQuestions", "companyReviews"].filter(
                (type) => types[type]
            );
        },
    },
    actions: {
        getNotificationCount({ commit }) {
            httpGet({
                url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/shop/getNotifications`,
            })
                .then(({ data }) => {
                    const keysUsed = [
                        "merchantReviewUnseenCount",
                        "productQuestionUnansweredCount",
                        "productReviewUnreadCount",
                        "newProductReviewsEnabled",
                        "productReviewsEnabled",
                        "productQuestionsEnabled",
                    ];
                    keysUsed.forEach((key) => {
                        commit("setNotificationCount", { key, count: data[key] });
                    });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    /* eslint-disable no-console */
                    console.error(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        },
        onAccountSwitch({ commit, dispatch }) {
            commit("setLoading", true);
            window.requestAnimationFrame(() => {
                dispatch("getNotificationCount");
            });
        },
    },
};
export default NotificationStore;
