import { ref, computed } from "vue-demi";
import { orderBy } from "lodash";
import Fuse from "fuse.js";

export const SORT_DIRECTION_ASCENDING = "asc";
export const SORT_DIRECTION_DESCENDING = "desc";
export const NO_SORT = "no_sort";

export function useTableState() {
    const originalData = ref([]);
    const filters = ref([]);
    const filtersApplied = ref(false);
    const sortColumn = ref(null);
    const sortDirection = ref(SORT_DIRECTION_ASCENDING);
    const noSortHandler = ref(null);
    const searchTerm = ref(null);
    const searchThreshold = ref(11);
    const searchColumns = ref([]);
    const searchTermThreshold = ref(0.6);
    const ignoreSearchLocation = ref(false);
    const paginationEnabled = ref(false);
    const paginationSize = ref(10);
    const paginationThreshold = ref(11);
    const paginationActivePageIndex = ref(0);
    const filteredData = ref([]);

    const setData = (
        tableData,
        options = {
            searchThreshold: 11,
            paginationEnabled: true,
            paginationThreshold: 11,
            selectable: true,
        }
    ) => {
        originalData.value = tableData.map((row) => {
            if (options.selectable && typeof row.isSelected === "undefined") {
                // eslint-disable-next-line no-param-reassign
                row.isSelected = false;
            }
            return row;
        });

        searchThreshold.value = options.searchThreshold ? options.searchThreshold : 11;
        if (options.searchTermThreshold) {
            searchTermThreshold.value = options.searchTermThreshold;
        }
        if (options.ignoreSearchLocation) {
            ignoreSearchLocation.value = options.ignoreSearchLocation;
        }

        if (Object.prototype.hasOwnProperty.call(options, "paginationEnabled")) {
            paginationEnabled.value = options.paginationEnabled;
            paginationThreshold.value = options.paginationThreshold;
        }
    };

    const setFilters = (filterDefinitions) => {
        filters.value = [...filterDefinitions];
    };

    const applyFilters = () => {
        // Set currentPage to 1
        paginationActivePageIndex.value = 0;
        filtersApplied.value = true;
    };

    const clearFilters = () => {
        filtersApplied.value = false;
    };

    const sort = ({ column, direction = SORT_DIRECTION_ASCENDING }) => {
        if (
            [SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING, NO_SORT].indexOf(direction) === -1
        ) {
            // eslint-disable-next-line no-console
            console.error(
                `[FC Error]: sortDirection argument must be either '${SORT_DIRECTION_ASCENDING}' or '${SORT_DIRECTION_DESCENDING}' or '${NO_SORT}'`
            );
            return;
        }

        // deselect sort on click, if selected
        if (sortColumn.value === column && sortDirection.value === direction) {
            sortColumn.value = null;
        } else {
            sortColumn.value = column;
            sortDirection.value = direction;
        }
    };

    const setNoSortHandler = (callbackFn) => {
        noSortHandler.value = callbackFn;
    };

    const search = ({ term, columns }) => {
        if (columns.length === 0) {
            // eslint-disable-next-line no-console
            console.error([
                "[FC Error]: search columnIndexes argument needs to be an array with at least one item",
            ]);
            return;
        }

        // An empty string equals no search term.
        if (term.length === 0) {
            searchTerm.value = null;
            searchColumns.value = [];
            return;
        }

        // Set currentPage to 1
        paginationActivePageIndex.value = 0;

        searchTerm.value = term;
        searchColumns.value = columns;
    };

    const currentPage = computed(() => paginationActivePageIndex.value + 1);

    const setPage = (page) => {
        paginationActivePageIndex.value = page - 1;
    };

    const setPaginationSize = (size) => {
        paginationSize.value = size;
    };

    const allSelected = computed(
        () => !!originalData.value.length && originalData.value.every((item) => item.isSelected)
    );

    const numberOfSelectedItems = computed(
        () => originalData.value.filter((item) => item.isSelected).length
    );

    const selectAll = (selection) => {
        if (selection) {
            filteredData.value.forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                item.isSelected = selection;
            });
        } else {
            originalData.value.forEach((item) => {
                // eslint-disable-next-line no-param-reassign
                item.isSelected = selection;
            });
        }
    };

    const deleteSelected = () => {
        originalData.value = originalData.value.filter((item) => !item.isSelected);
    };

    const data = computed(() => {
        const computedData = {
            pages: Math.ceil(originalData.value.length / paginationSize.value),
            itemCount: originalData.value.length,
            currentPage: currentPage.value,
            currentPageRangeStart: 0,
            currentPageRangeEnd: 0,
            data: [...originalData.value],
            rawData: originalData.value,
            currentSorted: sortColumn.value,
            currentSortDirection: sortColumn.value ? sortDirection.value : NO_SORT,
            searchEnabled: originalData.value.length >= searchThreshold.value,
            searchTerm: searchTerm.value,
            allSelected: allSelected.value,
            numberOfSelectedItems: numberOfSelectedItems.value,
            allFilteredSelected: false,
            paginationSize: paginationSize.value,
        };

        if (filtersApplied.value === true) {
            filters.value.forEach((filterPredicate) => {
                computedData.data = computedData.data.filter(filterPredicate);
            });
        }

        // eslint-disable-next-line no-param-reassign
        computedData.data.forEach((element) => delete element.matches);
        if (searchTerm.value !== null) {
            const searchEngine = new Fuse(computedData.data, {
                keys: searchColumns.value,
                includeScore: true,
                includeMatches: true,
                threshold: searchTermThreshold.value,
                ignoreLocation: ignoreSearchLocation.value,
            });
            computedData.data = searchEngine
                .search(searchTerm.value)
                .filter((result) => result.score < 0.5)
                .map((result) => {
                    // eslint-disable-next-line no-param-reassign
                    result.item.matches = result.matches;
                    return result.item;
                });
        }

        if (sortColumn.value !== null) {
            computedData.data = orderBy(
                computedData.data,
                [
                    (element) =>
                        element[sortColumn.value] ? element[sortColumn.value].toLowerCase() : null,
                ],
                [sortDirection.value]
            );
        }

        // Allow for custom data sorting when NO_SORT is the sortDirection
        if (
            computedData.currentSortDirection === NO_SORT &&
            typeof noSortHandler.value === "function"
        ) {
            const handlerResult = noSortHandler.value(computedData.data);
            if (!handlerResult) {
                // eslint-disable-next-line no-console
                console.error(["[FC Error]: noSortHandler function did not return table data."]);
            }
            computedData.data = handlerResult;
        }

        // capture state before pagination
        filteredData.value = computedData.data;
        computedData.allFilteredSelected =
            !!computedData.data.length && computedData.data.every((item) => item.isSelected);

        if (paginationEnabled.value && originalData.value.length >= paginationThreshold.value) {
            const paginatedData = [];
            let totalItemCount = 0;
            for (let i = 0; i < originalData.value.length; i += paginationSize.value) {
                const slice = computedData.data.slice(i, i + paginationSize.value);
                totalItemCount += slice.length;
                paginatedData.push(slice);
            }

            // making sure the current page is not out of bounds (e.g. after item deletion)
            if (currentPage.value > computedData.pages) {
                paginationActivePageIndex.value = computedData.pages - 1;
                computedData.currentPage = computedData.pages;
            }

            computedData.data = paginatedData[paginationActivePageIndex.value];
            computedData.currentPageRangeStart =
                paginationActivePageIndex.value * paginationSize.value + 1;
            computedData.currentPageRangeEnd =
                computedData.currentPageRangeStart + computedData.data.length - 1;
            computedData.itemCount = totalItemCount;
            computedData.pages = Math.ceil(totalItemCount / paginationSize.value);
        }

        return computedData;
    });

    return {
        data,
        setData,
        setFilters,
        filtersApplied,
        applyFilters,
        clearFilters,
        sort,
        setNoSortHandler,
        search,
        setPage,
        selectAll,
        deleteSelected,
        setPaginationSize,
    };
}
