<template>
    <div v-if="openPercentage > 0">
        <MainNavigation
            class="navigation"
            :style="navigationStyle"
            :open-percentage="openPercentage"
            :displayTreeNavigation="isDesktopTreeNavigationOpen"
        >
            <template v-slot:header>
                <DivisionSwitchButton
                    class="navigation__header"
                    @isDivisionSwitcherOpen="setTreeNavigationState"
                    :divisionSwitcherIsOpen="isTreeNavigationOpen"
                    :oneDivision="!treeData.length"
                />
            </template>
            <NavigationList v-for="(category, index) in navigationItemCategories" :key="index">
                <li
                    v-if="index === 'mobileDisabled' && category.length > 0"
                    class="mobile_disabled_indicator"
                >
                    {{ $t("cp__nav__disabled_on_mobile") }}
                </li>
                <template v-for="(navigationItem, index) in category">
                    <NavigationListItem
                        v-if="navigationItem.type !== 'category'"
                        :key="navigationItem.key + index"
                        :type="navigationItem.type"
                        :url="navigationItem.link"
                        :target="navigationItem.target"
                        :isActive="navigationItem.isActive"
                        :matchExact="navigationItem.matchExact"
                        :matchExactPath-="navigationItem.matchExactPath"
                        :class="{
                            'navigation_item--disabled_on_mobile': !navigationItem.mobile,
                        }"
                        @click.native="linkClicked(navigationItem)"
                    >
                        <template v-slot:icon>
                            <font-awesome-icon
                                class="navigation_item__icon"
                                :icon="['fal', navigationItem.icon]"
                                fixed-width
                            />
                        </template>
                        <div class="navigation_item__contents">
                            {{ $t(navigationItem.label_key) }}
                            <div
                                v-if="
                                    navigationItem.notificationCount &&
                                    notifications[navigationItem.notificationCount] > 0
                                "
                                class="navigation_item__notification_bubble"
                            >
                                {{ notifications[navigationItem.notificationCount] }}
                            </div>
                        </div>
                    </NavigationListItem>
                    <NavigationNestedList
                        v-else
                        :isActive="navigationItem.isActive"
                        :key="navigationItem.key + index"
                    >
                        <template v-slot:title>{{ $t(navigationItem.label_key) }}</template>
                        <template v-slot:icon>
                            <font-awesome-icon
                                class="navigation_item__icon"
                                :icon="['fal', navigationItem.icon]"
                            />
                        </template>
                        <NavigationNestedListItem
                            v-for="(subItem, index) in getVisibleSubItems(navigationItem.sub_items)"
                            :key="index"
                            :type="subItem.type"
                            :url="subItem.link"
                            @click.native="linkClicked(subItem)"
                            >{{ $t(subItem.label_key) }}</NavigationNestedListItem
                        >
                    </NavigationNestedList>
                </template>
            </NavigationList>
            <template v-slot:tree_navigation>
                <SearchTree
                    ref="searchTree"
                    sortable
                    :empty="data.data.length === 0"
                    :currentSorted="data.currentSorted"
                    :currentSortDirection="data.currentSortDirection"
                    :searchable="hasOver10TreeItems"
                    :searching="data.searchTerm !== null"
                    :paginate="false"
                    :treeData="mappedTreeData"
                    :selectItemHandler="switchDivision"
                    @search="search($event)"
                >
                </SearchTree>
            </template>
        </MainNavigation>
        <div class="mobile_navigation__overlay" @click="close('navigation')" />
        <div
            v-if="isDesktopTreeNavigationOpen"
            class="main__overlay"
            @click="closeTreeNavigation"
        />
        <div
            v-if="isDesktopTreeNavigationOpen"
            class="navigation__overlay"
            @click="closeTreeNavigation"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import numeral from "numeral";
import { vueWindowSizeMixin } from "vue-window-size";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faChartBar,
    faCog,
    faCommentDots,
    faCopy,
    faEnvelope,
    faHome,
    faInbox,
    faShapes,
    faStop,
    faUser,
    faChartLine,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import MainNavigation from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Layout/MainNavigation.vue";
import NavigationList from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/NavigationList.vue";
import NavigationListItem from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/NavigationListItem.vue";
import NavigationNestedList from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/NavigationNestedList.vue";
import NavigationNestedListItem from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/NavigationNestedListItem.vue";
import SearchTree from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/SearchTree.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import navigationStructure from "@/data/main-navigation-structure.yaml";
import DivisionSwitchButton from "@/components/Layout/Atoms/DivisionSwitchButton.vue";
import { useTableState } from "@/composables/useTableState";

library.add(
    faInbox,
    faHome,
    faCopy,
    faEnvelope,
    faShapes,
    faStop,
    faChartBar,
    faCog,
    faUser,
    faCommentDots,
    faChartLine
);

export default {
    name: "Navigation",
    mixins: [vueWindowSizeMixin],
    components: {
        FontAwesomeIcon,
        MainNavigation,
        NavigationList,
        NavigationListItem,
        NavigationNestedList,
        NavigationNestedListItem,
        DivisionSwitchButton,
        SearchTree,
    },
    setup() {
        const { data, setData, sort, search, searchTerm, selectAll, deleteSelected } =
            useTableState();
        return {
            data,
            setData,
            sort,
            search,
            searchTerm,
            selectAll,
            deleteSelected,
        };
    },
    computed: {
        ...mapState({
            storeOpenPercentage: (state) => state.navigation.openPercentage,
            trayOpenPercentage: (state) => state.AccountStore.divisionSwitchTrayOpenPercentage,
        }),
        ...mapGetters({
            notificationCount: "NotificationStore/notificationCount",
            activeAccount: "AccountStore/activeAccount",
            hasPermissions: "AccountStore/hasPermissions",
            treeData: "AccountStore/treeData",
        }),
        hasOver10TreeItems() {
            return !!this.data.data.length && this.data.data[0].children.length > 10;
        },
        mappedTreeData() {
            this.clearSearchState();
            this.data.data.forEach((element) => {
                if (element.matches) {
                    element.isRootMatched = !!element.matches.find((match) => match.key === "name");
                    element.matches.forEach((match) => {
                        if (match.key !== "name") {
                            element.children[match.refIndex].match = match;
                            element.isOpen = true;
                        }
                    });
                }
            });
            return this.data.data;
        },
        navigationItems() {
            return Object.keys(navigationStructure)
                .map((key) => ({ key, ...navigationStructure[key] }))
                .filter((navigationItem) => {
                    const allowed = this.hasPermissions(navigationItem.permissions_required);
                    if (navigationItem.type === "category") {
                        navigationItem.sub_items = navigationItem.sub_items.filter((subItem) =>
                            this.hasPermissions(subItem.permissions_required)
                        );
                    }
                    return allowed;
                })
                .map((navigationItem) => {
                    let isActive = this.$route.path === navigationItem.link;

                    if (!navigationItem.matchExact && navigationItem.link) {
                        isActive =
                            this.$route.path.indexOf(navigationItem.link.replaceAll("/", "")) > -1;
                    }

                    // eslint-disable-next-line no-unused-expressions
                    navigationItem.sub_items?.forEach((subItem) => {
                        // exception for widget pages
                        if (
                            subItem.link === "/widgets" &&
                            this.$route.path.substr(0, 8) === "/widgets"
                        ) {
                            isActive = true;
                            return;
                        }

                        if (this.$route.path !== subItem.link) return;

                        isActive = true;
                    });

                    return {
                        ...navigationItem,
                        isActive,
                    };
                });
        },
        navigationItemCategories() {
            const navigationItemCategories = {
                enabled: this.navigationItems,
            };
            if (this.isMobile) {
                const mobileDisabled = [];
                this.navigationItems.forEach((item) => {
                    if (item.sub_items) {
                        item.sub_items.forEach((subItem) => {
                            if (!subItem.mobile) {
                                subItem.icon = item.icon;
                                mobileDisabled.push(subItem);
                            }
                        });
                        // hide menu item if there are no subitems available on mobile
                        item.mobile = !!item.sub_items.find((subItem) => subItem.mobile);
                    }
                });
                navigationItemCategories.enabled = this.navigationItems.filter(
                    (navigationItem) => navigationItem.mobile
                );
                navigationItemCategories.mobileDisabled = mobileDisabled;
            }
            return navigationItemCategories;
        },
        navigationStyle() {
            const left = 0 - 248 * (1 - this.openPercentage);
            return {
                transform: `translateX(${left}px)`,
            };
        },
        openPercentage() {
            let openPercentage = this.storeOpenPercentage;
            if (!this.isMobileOrTablet) openPercentage = 1;
            return openPercentage;
        },
        notifications() {
            return {
                inbox: numeral(this.notificationCount).format("0a"),
            };
        },
        isTreeNavigationOpen() {
            return this.isDesktopTreeNavigationOpen || this.isMobileTreeNavigationOpen;
        },
        isMobileOrTablet() {
            return this.windowWidth < 1001;
        },
        isMobile() {
            return this.windowWidth < 769;
        },
    },
    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.open("navigation", this.openPercentage);
            } else {
                this.close("navigation");
            }
        },
        isMobileTreeNavigationOpen(isOpen) {
            if (isOpen) {
                this.open(
                    "AccountStore/divisionSwitchTrayOpenPercentage",
                    this.trayOpenPercentage,
                    200
                );
                this.isMobileTreeNavigationOpen = true;
            }
        },
        trayOpenPercentage(value) {
            if (value === 0) {
                this.isMobileTreeNavigationOpen = false;
            }
        },
    },
    data() {
        return {
            isDesktopTreeNavigationOpen: false,
            isMobileTreeNavigationOpen: false,
        };
    },
    methods: {
        ...mapActions({
            switchAccount: "AccountStore/switchAccount",
        }),
        open(method, openPercentage, timeValue = 160) {
            animateXtoY({
                x: openPercentage,
                y: 1,
                time: timeValue,
                executable: ({ setTo }) => {
                    this.$store.commit(method, {
                        openPercentage: setTo,
                    });
                },
            });
        },
        close(method, timeValue = 160) {
            animateXtoY({
                x: 1,
                y: 0,
                time: timeValue,
                executable: ({ setTo }) => {
                    this.$store.commit(method, {
                        openPercentage: setTo,
                    });
                },
            });
        },
        closeTreeNavigation() {
            this.isDesktopTreeNavigationOpen = false;
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        linkClicked(item) {
            this.close("navigation");
            if (this.$route.fullPath === item.link && item.allowDoubleNavigation) {
                this.$emit("refreshView");
            }
        },
        setTreeNavigationState(isOpen) {
            if (this.isMobileOrTablet) {
                this.isMobileTreeNavigationOpen = isOpen;
            } else {
                this.isDesktopTreeNavigationOpen = isOpen;
            }
        },
        async switchDivision(selectedItem) {
            if (!selectedItem.children && !selectedItem.isActive) {
                await this.switchAccount({ uuid: selectedItem.uuid, apolloClient: this.$apollo });
                this.isDesktopTreeNavigationOpen = false;
                this.isMobileTreeNavigationOpen = false;
                this.data.data.forEach((element) => {
                    element.isActive = false;
                    element.children.forEach((child) => {
                        child.isActive = false;
                    });
                });
                selectedItem.isActive = true;
                this.clearSearchbar();
            }
        },
        getVisibleSubItems(subItems) {
            if (this.isMobile) {
                return subItems.filter((item) => item.mobile);
            }
            return subItems;
        },
        clearSearchState() {
            this.data.data.forEach((element) => {
                element.isRootMatched = false;
                element.children.forEach((child) => {
                    delete child.match;
                });
            });
        },
        clearSearchbar() {
            this.$refs.searchTree.clearSearchbar();
        },
    },
    created() {
        this.setData(this.treeData, { searchTermThreshold: 0.3, ignoreSearchLocation: true });
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";

.navigation,
.navigation__categories,
.navigation__list,
.navigation__holder,
.navigation__list_item {
    margin: 0px;
    padding: 0px;
}

.navigation {
    position: relative;
    z-index: 10;
    height: calc(100vh - 48px);

    @include media($isDesktop...) {
        height: calc(100vh - 56px);
    }
}

.mobile_navigation__overlay {
    width: 100%;
    background-color: $white;
    opacity: 0.5;
    position: fixed;
    top: 48px;
    left: 0px;
    z-index: 1;
    height: 100%;

    @media (max-width: 1000px) {
        height: 100%;
    }
    @media (min-width: 1001px) {
        display: none;
    }
}

.main__overlay {
    width: 100%;
    background-color: $white;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    opacity: 0.5;
}

.navigation__overlay {
    width: 250px;
    background-color: $white;
    position: fixed;
    top: 136px;
    left: 0px;
    z-index: 1;
    height: 100%;
    opacity: 0.5;
    z-index: 10;
}

.navigation__mobile_header {
    display: flex;
    align-items: center;
    height: 96px;
    padding: 0px 24px;
    border-bottom: 1px solid $grey_alabaster;
    flex-shrink: 0;
}

.navigation__mobile_footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 96px;
    padding: 0px 24px;
    border-top: 1px solid $grey_alabaster;
    flex-shrink: 0;
}

.navigation__category {
    @extend %body2_style;
    text-transform: uppercase;
    margin: 17px 0;
    padding: 0 24px;
}

.navigation_item {
    @extend %body2_style;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    width: calc(100% + 1px);
    height: 48px;
    padding: 0px 24px;
    border-right: 2px solid rgba($white, 0);

    &.navigation_item--match-loose.router-link-active {
        background: rgba($blue, 0.05);
        border-right: 2px solid $blue;

        .navigation_item__icon {
            color: $blue;
        }
    }

    &.navigation_item--match-exact.router-link-exact-active {
        background: rgba($blue, 0.05);
        border-right: 2px solid $blue;

        .navigation_item__icon {
            color: $blue;
        }
    }

    &.navigation_item--disabled {
        @media (max-width: 768px) {
            color: $grey_french;
            pointer-events: none;
        }
    }
}

.mobile_disabled_indicator {
    @extend %body2_style;
    width: 100%;
    display: flex;
    padding: 12px 24px;
    font-weight: 600;
}

.navigation_item__name {
    width: 100%;
}

.navigation_item__contents {
    width: 100%;
    display: inline;
}

.navigation_item__notification_bubble {
    @extend %body2_style;
    min-width: 16px;
    height: 16px;
    padding: 0 5px;
    margin-left: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 0.66em;
    color: $white;
    background-color: $notification_red;
    flex-shrink: 0;
}

.nagigation__list_item--header {
    padding-left: 24px;
}

.navigation_item__icon {
    margin-right: 16px;
}

.navigation_item--disabled_on_mobile {
    @media (max-width: 768px) {
        opacity: 0.5;
        pointer-events: none;
    }
}

.navigation__header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0px 24px 0px 19px;
    border-right: 1px solid $grey_alabaster;
    border-bottom: 1px solid $grey_alabaster;
    flex-shrink: 0;
}

::v-deep .tree_navigation__categories {
    padding: 0 !important;
}
::v-deep .table__container {
    border: none !important;
    padding-top: 0 !important;
    & > .tree {
        padding-right: 24px;
        & > .item {
            margin: 0;
        }
    }
    & .searchbar_holder {
        margin-top: 28px;
    }
}
::v-deep .divider {
    display: none;
}
</style>
