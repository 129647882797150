import WidgetService from "./WidgetService";
import ChartService from "./ChartService";
import FilterService from "./FilterService";
import SplitByService from "./SplitByService";
import ChartExportService from "./ChartExportService";
import QueryService from "./QueryService";
import AxisService from "./AxisService";
import FilterTemplateService from "./FilterTemplateService";

const widgetService = new WidgetService();
const chartService = new ChartService();
const filterService = new FilterService();
const splitByService = new SplitByService();
const chartExportService = new ChartExportService();
const queryService = new QueryService();
const axisService = new AxisService();
const filterTemplateService = new FilterTemplateService();

export {
    widgetService,
    chartService,
    filterService,
    splitByService,
    chartExportService,
    queryService,
    axisService,
    filterTemplateService,
};
